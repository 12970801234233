<template>
	<div id="goodsTypeList">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-input-search style="width: 400px;" v-model:value="search.key" placeholder="分类名称" enter-button @search="getTypeList()" />
				<a-button type="primary" v-has="{action:'wxapp_goods_type_add',plat:isShop}" @click="editType(0)"><i class="ri-add-line ri-lg ri-top"></i>新增分类</a-button>
				<a-button type="danger" v-has="{action:'wxapp_goods_type_del',plat:isShop}" @click="deleteType(pks)"><i class="ri-delete-bin-line ri-lg ri-top"></i>批量删除</a-button>
			</a-space>
		</div>
		
		<div class="el-content" v-if="list.length > 0">
			<a-table row-key="id" size="small" :pagination="false" :data-source="list" :defaultExpandAllRows="true" :columns='[
				{dataIndex:"id",title:"ID"},
				{dataIndex:"icon",title:"图标",slots: { customRender: "icon" }},
				{dataIndex:"name",title:"分类名称"},
				{dataIndex:"is_show",title:"状态",slots:{customRender:"is_show"}},
				{dataIndex:"rank",title:"排序"},
				{dataIndex:"update_time",title:"最后更新时间"},
				{dataIndex:"action",title:"操作",slots:{customRender:"action"}},
			 ]' :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
				<template #icon="{record}">
					<a-avatar :src="record.icon"/>
				</template>
				<template #is_show="{record}">
					<a-tag color="#00CC66" v-if="record.is_show==1" >显示</a-tag>
					<a-tag color="#999" v-if="record.is_show==0" >隐藏</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<kd-button 
							type="primary" 
							icon="ri-edit-2-line" 
							title="编辑" 
							@click="editType(record)" 
							v-has="{action:'wxapp_goods_type_add',plat:isShop}">
						</kd-button>
						<kd-button 
							type="danger" 
							icon="ri-delete-bin-5-line" 
							title="删除" 
							@click="deleteType([record.id])" 
							v-has="{action:'wxapp_goods_type_del',plat:isShop}">
						</kd-button>
					</a-space>
				</template>
			</a-table>
		</div>
		<a-modal v-model:visible="show.edit" title="添加商品分类" @ok="saveGoodsType" width="600px" @cancel="show.edit =false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="上级分类">
					<a-select v-model:value="form.parent_id">
						<a-select-option :value="0" label="请选择上级分类"></a-select-option>
						<a-select-option :value="val.id" v-for="(val,ind) in list" :key="ind">{{val.name}}</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="分类名称" required>
					<a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="分类图标" class="cover-input" name="icon">
					<kd-img-select :src="form.icon" @change="(url)=>{ form.icon = url }"></kd-img-select>
				</a-form-item>
				<a-form-item label="分类排序" >
					<a-input v-model:value="form.rank" type="number"></a-input>
				</a-form-item>
				<a-form-item label="是否显示" >
					<kd-switch :value="form.is_show" @change="e=>form.is_show = e" :active-value="1" :inactive-value="0"></kd-switch>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import goodsModel from '@/api/goods.js'
import common from '@/api/common.js'
export default{
	name:'page-type-list',
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	setup(){
		const _d = reactive({
			search:{key:""},
			pks:[],
			list:[],
			show:{ edit:false, },
			form:null,
		})
		getTypeList()
		function getTypeList(){
			goodsModel.getCategroyList(1,999,_d.search,res=>{
				_d.list = res
				_d.list.forEach(item=> item.children = item.child )
			})
		}
		function editType(row){
			_d.form = {
				id:row? row.id : 0 ,
				name:row? row.name : '',
				icon:row? row.icon : '',
				type:row? row.type : 1,
				rank:row? row.rank : 99,
				is_show:row ? row.is_show : 1,
				parent_id:row? row.parent_id : 0
			}
			_d.show.edit = true
		}
		const deleteType = (id)=>common.deleteDataList(id,7,"确认删除该商品分类吗?").then(()=>getTypeList())
		const saveGoodsType = ()=>goodsModel.addOrEditCategory(_d.form,()=>{
			_d.show.edit = false
			getTypeList()
		})
		return{
			...toRefs(_d),
			deleteType,
			getTypeList,
			editType,
			saveGoodsType
		}
	}
}
</script>

<style lang="scss">
</style>
